<template>
    <div class="login-page">
        <div class="logo-corner">
            <img :src="logoSrc">
        </div>

        <div class="login-con">
            <div class="login-box">
                <div slot="header" class="clearfix">
                    <h1>{{CONFIG_PROJECT.title}}</h1>
                </div>
                <my-form 
                v-model="form"
                autocomplete="off" 
                label-width="0"
                @submit="submitHandler"
                >
                    <el-form-item 
                    prop="mobile"
                    :rules="newRule('手机号', 'required', 'mobile')"
                    >
                        <el-input 
                            class="login-input"
                            v-model="form.mobile" 
                            clearable 
                            autocomplete="off" 
                            :placeholder="`请输入手机号`"
                        ></el-input>
                    </el-form-item>

                    <el-form-item
                    prop="yzm"
                    :rules="newRule('验证码', 'required')"
                    >
                        <input-yzm
                            class="login-input"
                            :mobile="form.mobile"
                            v-model="form.yzm"
                            :placeholder="`请输入验证码`"
                        ></input-yzm>
                    </el-form-item>

                    <template slot="btn" slot-scope="scope">
                        <el-button 
                        type="primary" 
                        class="btn-login" 
                        @click="scope.submitHandler"
                        >
                            注册 / 登录
                        </el-button>

                        <div class="info">
                            登录代表您已同意{{CONFIG_PROJECT.title}}服务协议和隐私政策
                        </div>
                    </template>
                </my-form>
            </div>
        </div>
    </div>
</template>

<script>
import CONFIG_PROJECT from '@config/project';

import logo from '@images/logo.png';
import InputYzm from '@components/sys/input-yzm'

export default {
    head: {
        title: '登录'
    },
    components: {
        InputYzm,
    },
    layout: 'empty',
    data() {
        return {
            CONFIG_PROJECT,

            form: {
                mobile: '',
                yzm: '',
            },

            logoSrc: logo,
        }
    },
    methods: {
        submitHandler() {
            this.$store.dispatch('userinfo/smsloginHandler', {
                data: this.form,
                success: () => {
                    if (this.getQuery('redirect')) {
                        this.$router.replace(this.getQuery('redirect'));
                    } else if (new RegExp(window.location.origin).test(document.referrer)) {
                        this.$router.go(-1);
                    } else {
                        this.$router.replace('/');
                    }
                }
            });
        },
    },
    mounted() {
        
    }
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .login-page{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background:url(/images/login/bg.jpg) no-repeat $primaryColor; 
        background-size:cover;
    }

    .logo-corner{
        position: absolute; 
        left: 50%;
        top: 30px;
        transform: translateX(-600px);

        img{
            height: 48px;
        }
    }

    .login-con{
        width:470px; 
        position:absolute; 
        left:50%; 
        top:calc(50% - 40px); 
        transform:translate(-50%, -50%);

        .login-box{
            border-radius:0;
            background-color: rgba(255, 255, 255, .9);
            padding: 50px 80px;
            border-radius: 5px;

            h1{ 
                text-align: center; 
                font-size:36px;
                font-weight: normal;
                margin-bottom: 60px;
            }

            .el-form-item--small.el-form-item{
                margin-bottom: 40px;
            }

            .login-input{
                border-bottom: 1px solid #e0e4e6;

                ::v-deep{
                    .el-input__inner {
                        background: transparent;
                        border: none;
                        border-radius: 0;
                        padding: 0;
                    }

                    .el-input-group__append{
                        border: none;
                        background: transparent;

                        &:hover{
                            color: $primaryColor7;
                        }

                        .btn-yzm{
                            padding: 9px 0;
                        }
                    }
                }
            }

            .btn-login{
                width:100%;
                font-size: 16px;
                padding: 15px;
            }
        };
    };

    .info{
        color: #666666;
        margin-top: 50px;
        line-height: 1em;
    }
</style>